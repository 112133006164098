<h2 class="more-info-title">
    More Information about PDF Power
</h2>
<div class="more-info-container">

    <div class="more-info-row row-1">
        <div class="info">
            <h5>
                Over a 100 Million Users
            </h5>
            <p class="description">
                Yes, it is correct! Since we launched our PDF Converter tool in 2015, we've helped over 100 million people make PDF Convert simple.
            </p>
        </div>

        <div class="info">
            <h5>
                Used in More Than 190 Countries
            </h5>
            <p class="description">
                PDFs, as well as PDF Power tools, are used in every country on the planet. Even in Antarctica, we're exploited!
            </p>
        </div>

        <div class="info">
            <h5>
                There Is No Download Limit!
            </h5>
            <p class="description">
                With our free software you can convert and download as many PDF files as you want, there is no limit to the amount you can download.
            </p>
        </div>

    </div>

    <div class="more-info-row row-2">
        <div class="info">
            <h5>
                Highly Rated
            </h5>
            <p class="description">
                The most popular PDF website in the United States, and consistently among the top 100 most popular PDF websites worldwide.
            </p>
        </div>

        <div class="info">
            <h5>
                Maintenance & Improvement
            </h5>
            <p class="description">
                Our team has ongoing development and maintenance tasks, so you are part of a product that always improves itself.
            </p>
        </div>

        <div class="info">
            <h5>
                Any Device, Anytime!
            </h5>
            <p class="description">
                We're all over the place, and so are PDFs. On the web, desktop, and mobile, we keep those simple chores simple.
            </p>
        </div>
    </div>
</div>