<div class="page-wrapper">
  <app-header>

  </app-header>
  <!-- <app-ads (linkClicked)="openSearchLink($event)">

  </app-ads> -->
  <h1 class="main-title">
    PDF Online Converter
  </h1>
  <p class="main-description">
    Convert files from and to PDF for free
  </p>
  <div class="adsense-row-wrapper">

    <ng-adsense [adSlot]="3298173372" adFormat="inline"  [display]="'block'" [width]="300" [height]="250"></ng-adsense>
    <type-pdf></type-pdf>
    <ng-adsense [adSlot]="7812133453" adFormat="inline"  [display]="'block'" [width]="255" [height]="533"></ng-adsense>
  </div>


  <app-advantages>

  </app-advantages>

  <!-- <app-ads (linkClicked)="openSearchLink($event)">

  </app-ads> -->
  <div class="adsense-row-wrapper more-info-outer-wrapper">

    <ng-adsense [adSlot]="5185970119" adFormat="inline"  [display]="'block'" [width]="266" [height]="533"></ng-adsense>
    <app-more-info>

    </app-more-info>
    <ng-adsense [adSlot]="7620561769"  adFormat="inline"  [display]="'block'" [width]="300" [height]="250"></ng-adsense>
  </div>


  <app-footer (linkClicked)="openCaughtModal()">

  </app-footer>

  <app-modal #modal [modalConfig]="{}">
    <div class="caught-modal-wrapper">
      <h3 class="modal-title">
        Hello! You caught us before we’re ready.
      </h3>
      <p class="modal-desc">
        We’re working hard to put the finishing touches onto PDF Power.
        Things are going well and it should be ready to help you with other features anytime soon.
        for any other concern you can reach us on support@mytechmedia.net
      </p>
    </div>
  </app-modal>
</div>