<div class="container">
    <footer class="footer">
        <div class="links">
            <a href="https://www.pdfconverterpower.com/privacy.html">
                Privacy
            </a>
            <a href="https://www.pdfconverterpower.com/eula.html">
                EULA
            </a>
            <a href="https://www.pdfconverterpower.com/contact.html">
                Contact
            </a>
            <a (click)="handleClick($event)" href="#">
                Blog
            </a>
        </div>
        <p>
            © PDF Power, 2015–2022
        </p>
        <p>

        </p>
    </footer>
</div>