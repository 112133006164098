import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'PDF Converter Power';
  @ViewChild('modal') public modalComponent: ModalComponent;
  
  constructor(private titleService: Title) {
    this.titleService.setTitle(this.title);
  }

  openCaughtModal() {
    this.modalComponent.open();
  }
  
  openSearchLink(title) {
    window.open(
      `https://searc.me/03289e8f-6d50-4ad1-806a-2a0708038a5d?q=${title}&chname=cel_`,
      '_blank'
    );
  }
}