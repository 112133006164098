import { Component, EventEmitter, Output } from '@angular/core';
import { GtmDataLayerService } from '@app/services/gtm-data-layer.service';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss']
})
export class AdsComponent {
  links: any[] = [
  {title: 'Free PDF Editor', href: '', gaCategory: 'free_pdf_editor'},
  {title: 'Adobe PDF Editor', href: '', gaCategory: 'adobe_pdf_editor'},
  {title: 'PNG to PDF', href: '', gaCategory: 'png_to_pdf'},
  {title: 'Free Online PDF', href: '', gaCategory: 'free_online_pdf'},
  {title: 'PDF Editor', href: '', gaCategory: 'pdf_editor'},
  {title: 'JPG to PDF', href: '', gaCategory: 'jpg_to_pdf'},]
  @Output() linkClicked = new EventEmitter();

  constructor(private gtm: GtmDataLayerService ) {}

  handleClick(event: MouseEvent, link) {
    this.gtm.logEvent('ga_event', link.gaCategory, link.gaCategory + '_click', null)
    event.preventDefault();
    const kebabTitle = link.gaCategory.split('_').join('-');
    this.linkClicked.emit(kebabTitle);
  }
}