import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Output() linkClicked = new EventEmitter();

  handleClick(event: MouseEvent) {
    event.preventDefault();
    this.linkClicked.emit();
  }
}